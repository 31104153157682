module.exports = {
  locales: ['en', 'pt', 'fr', 'es', 'it', 'nb', 'da', 'de', 'nl'],
  defaultLocale: 'en',
  id: 8,
  logo: {
    sources: [
      {
        media: '(max-width: 1024px)',
        srcSet: `${process.env.PUBLIC_PREFIX}/svg/tenants/8/logo-v2-mobile.svg`,
      },
    ],
    img: `${process.env.PUBLIC_PREFIX}/svg/tenants/8/logo-v2.svg`,
    height: '35px',
  },
  font: {
    name: 'CitroenType',
    files: [
      {
        file: `${process.env.PUBLIC_PREFIX}/fonts/8/CitroenType-Light.woff2`,
        weight: 300,
      },
      {
        file: `${process.env.PUBLIC_PREFIX}/fonts/8/CitroenType-Bold.woff2`,
        weight: 700,
      },
    ],
  },
};
